import React, { ChangeEvent, FC, useMemo } from 'react';
import {
  AddressVisualDataType,
  BooleanVisualDataType,
  DateVisualDataType,
  NumericVisualDataType,
  StringVisualDataType,
  TableVisualDataType,
  VisualDataType,
} from 'product_modules/enums/VisualDataType';
import {
  DateVisualAttributes,
  IdentificationTypeVisualAttributes,
  ListVisualDataTypeAttributes,
  MonetaryVisualAttributes,
  PhoneNumberVisualAttributes,
  TableVisualAttributes,
  VariableVisualAttributes,
} from 'product_modules/api/Core/VariablesApi';
import { getCurrencySymbol } from 'product_modules/components/CurrencySelect/currencies';
import getInputComponentByVisualType, {
  InputComponentsByVisualType,
} from 'product_modules/components/InputWithDataType/getInputComponentByVisualType';
import { BasicInputProps } from 'product_modules/components/InputWithDataType/InputWithDataType';
import { InputSize } from 'product_modules/components/DatePicker/Input';
import { Option } from 'product_modules/components/SelectInput/SelectInput';
import { AddressValue, TableValue, VariableValue } from 'product_modules/api/Types';
import styles from './InputWithDataType.module.scss';

interface InputComponentProps {
  value: string;
  nonFormattedValue: VariableValue;
  visualDataType: VisualDataType;
  visualAttributes: VariableVisualAttributes;
  showErrorMessage?: () => void;
  hideErrorMessage?: () => void;
  inputSize?: InputSize;
  errorMessage: string;
  country?: string;
  classNames?: Partial<{
    address: Partial<{
      sectionClassName?: string;
      header?: string;
      popupContent?: string;
      closeIcon?: string;
      title?: string;
      saveButton?: string;
    }>;
    table: Partial<{
      tableHeaderViewMode?: string;
      headerContainerExpanded?: string;
      tableContainerExpanded?: string;
      labelExpanded?: string;
      collapseButton?: string;
    }>;
  }>;
  useSimplifiedInput?: boolean;
  style?: React.CSSProperties;
  inputRef?: React.Ref<HTMLInputElement>;
  hidePlaceholder?: boolean;
  disabledValidation?: boolean;
  disableCapAttributesValidation?: boolean;
  dropdownClassName?: string;
  labelTooltipClassName?: string;
}

const BOOLEAN_VALUE_DROPDOWN_OPTIONS: Option[] = [
  { name: 'True', value: 'true' },
  { name: 'False', value: 'false' },
];

const TEXT_DATA_TYPE_MAX_ROWS = 4;
const TEXT_DATA_TYPE_SIMPLIFIED_MAX_ROWS_COUNT = 1;

const InputComponent: FC<InputComponentProps & BasicInputProps> = ({
  visualDataType,
  visualAttributes,
  showErrorMessage,
  hideErrorMessage,
  inputSize,
  nonFormattedValue,
  country,
  classNames,
  disabledValidation,
  disableCapAttributesValidation,
  hidePlaceholder,
  ...inputProps
}) => {
  const Component = useMemo(() => getInputComponentByVisualType(visualDataType), [visualDataType]);

  const getTextInputMaxRowsCount = useMemo(() => {
    if (visualDataType !== StringVisualDataType.Text) {
      return undefined;
    }

    if (inputProps.useSimplifiedInput) {
      return TEXT_DATA_TYPE_SIMPLIFIED_MAX_ROWS_COUNT;
    }

    return TEXT_DATA_TYPE_MAX_ROWS;
  }, [visualDataType, inputProps.useSimplifiedInput]);

  switch (visualDataType as keyof InputComponentsByVisualType) {
    case StringVisualDataType.PhoneNumber:
      const PhoneNumberComponent = Component as InputComponentsByVisualType[StringVisualDataType.PhoneNumber];

      return (
        <PhoneNumberComponent
          {...inputProps}
          country={(visualAttributes as PhoneNumberVisualAttributes).phoneNumberFormat}
          withFlag={!inputProps.useSimplifiedInput}
        />
      );
    case StringVisualDataType.IdentificationNumber:
      const IdentificationNumberComponent = Component as InputComponentsByVisualType[StringVisualDataType.IdentificationNumber];
      return (
        <IdentificationNumberComponent
          {...inputProps}
          identificationNumberType={(visualAttributes as IdentificationTypeVisualAttributes).identificationNumberType!}
          identificationNumberDescription={(visualAttributes as IdentificationTypeVisualAttributes).identificationNumberDescription}
        />
      );
    case NumericVisualDataType.Monetary:
      const MonetaryComponent = Component as InputComponentsByVisualType[NumericVisualDataType.Monetary];

      return (
        <MonetaryComponent
          {...inputProps}
          placeholder={hidePlaceholder || inputProps.useSimplifiedInput ? '' : visualDataType}
          currencySymbol={(visualAttributes as MonetaryVisualAttributes).currency
            ? getCurrencySymbol((visualAttributes as MonetaryVisualAttributes).currency)
            : null}
        />
      );
    case DateVisualDataType.Date:
      const DateComponent = Component as InputComponentsByVisualType[DateVisualDataType.Date];
      return (
        <DateComponent
          {...inputProps}
          inputSize={inputSize}
          dateFormat={(visualAttributes as DateVisualAttributes).dateFormat || undefined}
          showErrorMessage={showErrorMessage}
          hideErrorMessage={hideErrorMessage}
        />
      );
    case StringVisualDataType.List: {
      const ListComponent = Component as InputComponentsByVisualType[StringVisualDataType.List];

      const selectedOption = inputProps.value ? {
        name: inputProps.value,
        value: inputProps.value,
      } : undefined;

      const formattedOptionsList = ((visualAttributes as ListVisualDataTypeAttributes).optionsList || []).map((option) => ({
        name: option,
        value: option,
      }));

      return (
        <ListComponent
          {...inputProps}
          selectedOption={selectedOption}
          options={formattedOptionsList || []}
          onChange={({ value: optionValue }) => inputProps.onChange(optionValue)}
          placeholder={hidePlaceholder ? '' : visualDataType}
          ref={inputProps.inputRef}
          usePortal
        />
      );
    }
    case AddressVisualDataType.Address:
      const AddressComponent = Component as InputComponentsByVisualType[AddressVisualDataType.Address];
      return <AddressComponent
        {...inputProps}
        value={nonFormattedValue as AddressValue}
        placeholder={hidePlaceholder ? '' : visualDataType}
        country={country}
        classNames={classNames?.address}
      />
    case NumericVisualDataType.Percentage:
      const PercentageComponent = Component as InputComponentsByVisualType[NumericVisualDataType.Percentage];

      return (
        <PercentageComponent
          {...inputProps}
          placeholder={hidePlaceholder || inputProps.useSimplifiedInput ? '' : visualDataType}
        />
      );
    case NumericVisualDataType.Number:
      const NumberComponent = Component as InputComponentsByVisualType[NumericVisualDataType.Number];
      return (
        <NumberComponent
          {...inputProps}
          onBlur={(_value, event) => inputProps.onBlur?.(event)}
          placeholder={hidePlaceholder ? '' : visualDataType}
        />
      );
    case BooleanVisualDataType.Boolean: {
      const BooleanComponent = Component as InputComponentsByVisualType[BooleanVisualDataType.Boolean];
      const getAutoCompletionProps = () => {
        if (inputProps.raw && !BOOLEAN_VALUE_DROPDOWN_OPTIONS.some((option) => option.value === inputProps.value)) {
          const selectedOption = {
            name: inputProps.value,
            value: inputProps.value,
          };

          return {
            ...inputProps,
            selectedOption,
          };
        }

        return inputProps;
      };

      return (
        <BooleanComponent
          {...getAutoCompletionProps()}
          options={BOOLEAN_VALUE_DROPDOWN_OPTIONS}
          onChange={({ value: optionValue }) => inputProps.onChange(optionValue)}
          placeholder={hidePlaceholder ? '' : visualDataType}
          ref={inputProps.inputRef}
          usePortal
        />
      );
    }
    case StringVisualDataType.EmailAddress:
      const EmailComponent = Component as InputComponentsByVisualType[StringVisualDataType.EmailAddress];

      return <EmailComponent
        {...inputProps}
      />;
    case TableVisualDataType.Table:
      const TableComponent = Component as InputComponentsByVisualType[TableVisualDataType.Table];

      return (
        <TableComponent
          value={nonFormattedValue as TableValue}
          columns={(visualAttributes as TableVisualAttributes).columns}
          label={inputProps.labelTitle}
          onChange={inputProps.onChange}
          disableCapAttributesValidation={disableCapAttributesValidation}
          disabledValidation={disabledValidation}
          disabled={inputProps.disabled}
          classNames={{ ...classNames?.table, labelTooltip: inputProps.labelTooltipClassName }}
          required={inputProps.required}
          titleHint={inputProps.titleHint}
        />
      );
    default:
      const TextComponent = Component as InputComponentsByVisualType[StringVisualDataType.Text];

      return (
        <div className={styles.container}>
          <TextComponent
            {...inputProps}
            onChange={({ target }: ChangeEvent<HTMLInputElement>) => inputProps.onChange(target.value)}
            placeholder={hidePlaceholder ? '' : visualDataType}
            multiline={visualDataType === StringVisualDataType.Text}
            maxRows={getTextInputMaxRowsCount}
          />
        </div>
      );
  }
};

export default InputComponent;
