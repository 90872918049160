import React, { FC, useState, useEffect, useCallback } from 'react';
import { nanoid } from 'nanoid';
import { TableRowValue, TableValue, BasicVariableValue } from 'product_modules/api/Types';
import { TableColumn } from 'product_modules/api/Core/VariablesApi';
import { removeAt, replaceAt, insertAt } from 'product_modules/utils/arrayUtils';
import { isEmptyVariableValue } from 'product_modules/utils/isEmptyVariableValue';
import TableInputHeader from 'product_modules/components/TableInput/TableInputHeader';
import TableInputViewMode from 'product_modules/components/TableInput/TableInputViewMode';
import TableInputEditMode from 'product_modules/components/TableInput/TableInputEditMode';
import { MAX_TABLE_ROWS_AMOUNT } from 'product_modules/components/TableInput/TableInputEditMode/TableInputEditMode';
import styles from './TableInput.module.scss';
import useCurrentScreenWidth from 'product_modules/hooks/useCurrentScreenWidth';

const MAX_TABLET_WIDTH = 936;

interface TableInputProps {
  label: string;
  columns: TableColumn[];
  toggleFullScreen: () => void;
  value?: TableValue | null;
  viewMode?: boolean;
  onChange?: (value: TableValue | null) => void;
  isFullScreen?: boolean;
  disabledValidation?: boolean;
  disableCapAttributesValidation?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  onErrorMessageUpdate?: (info: Record<string, boolean>) => void;
  isViewModeChanged?: boolean;
  errors?: Record<string, boolean>;
  classNames?: {
    tableHeaderViewMode?: string;
    headerContainerExpanded?: string;
    tableContainerExpanded?: string;
    labelExpanded?: string;
    collapseButton?: string;
    labelTooltip?: string;
  };
  required?: boolean;
  titleHint?: string;
  dropdownClassName?: string;
}

const removeEmptyCellsFromTableRow = (
  previousRow: TableRowValue,
  updatedCellKey: string,
  updatedCellValue: BasicVariableValue,
) => {
  const updatedRow = { ...previousRow, [updatedCellKey]: updatedCellValue };

  return Object.keys(updatedRow).reduce((accumulator, key) => {
    if (!isEmptyVariableValue(updatedRow[key])) {
      accumulator[key] = updatedRow[key];
    }

    return accumulator;
  }, {} as TableRowValue);
};

const TableInput: FC<TableInputProps> = ({
  label,
  value,
  columns,
  viewMode,
  onChange,
  toggleFullScreen,
  isFullScreen,
  errorMessage,
  onErrorMessageUpdate,
  isViewModeChanged,
  classNames,
  errors,
  required,
  titleHint,
  ...props
}) => {
  const [initialTableInputValue] = useState<TableValue | null>(value || null);
  const [tableInputValue, setTableInputValue] = useState<TableValue>(value || []);
  const [latestAddedRowIndex, setLatestRowIndex] = useState<number>(-1);

  const handleInsertRowClick = useCallback((rowIndex: number) => {
    setTableInputValue((previousValue) => {
      return insertAt(previousValue, rowIndex, { _id: nanoid()});
    });

    setLatestRowIndex(rowIndex);
  }, []);

  const handleClearRowClick = useCallback((rowIndex: number, rowId: string) => {
    setTableInputValue((previousValue) => {
      return replaceAt(previousValue, rowIndex, { _id: nanoid() });
    });

    setLatestRowIndex(rowIndex);

    onErrorMessageUpdate?.(columns.reduce<Record<string, boolean>>((accumulator, column) => {
      accumulator[`${rowId}-${column.systemName}`] = false;

      return accumulator;
    }, {}));
  }, []);

  const handleDeleteRowClick = useCallback((rowIndex: number, rowId: string) => {
    setTableInputValue((previousValue) => {
      return removeAt(previousValue, rowIndex);
    });

    setLatestRowIndex(rowIndex);

    onErrorMessageUpdate?.(columns.reduce<Record<string, boolean>>((accumulator, column) => {
      accumulator[`${rowId}-${column.systemName}`] = false;

      return accumulator;
    }, {}));
  }, []);

  const handleRowUpdate = useCallback((id: string, updatedCellKey: string, updatedCellValue: BasicVariableValue) => {
    setTableInputValue((previousValue) => {
      const index = previousValue.findIndex((row) => row._id === id);

      if (previousValue.length > 1) {
        return replaceAt(previousValue, index, { ...previousValue[index], [updatedCellKey]: updatedCellValue });
      }

      const updatedRow = removeEmptyCellsFromTableRow(previousValue[index], updatedCellKey, updatedCellValue);

      return replaceAt(previousValue, index, updatedRow);
    });
  }, []);

  const handleDuplicateRowClick = useCallback((rowIndex: number, rowId: string) => {
    const newRowId = nanoid();

    setTableInputValue((previousValue) => {
      return insertAt(previousValue, rowIndex + 1, { ...previousValue[rowIndex], _id: newRowId });
    });

    setLatestRowIndex(rowIndex + 1);

    onErrorMessageUpdate?.(columns.reduce<Record<string, boolean>>((accumulator, column) => {
      accumulator[`${newRowId}-${column.systemName}`] = errors?.[`${rowId}-${column.systemName}`] ?? false;

      return accumulator;
    }, {}));
  }, [errors]);

  const handleAddRowClick = useCallback((currentTableLength: number) => {
    setTableInputValue((previousValue) => [...previousValue, { _id: nanoid() }]);

    setLatestRowIndex(currentTableLength);
  }, []);

  useEffect(() => {
    onChange?.((!tableInputValue?.length && !initialTableInputValue?.length) ? initialTableInputValue : tableInputValue);
  }, [tableInputValue]);

  useEffect(() => {
    if (value) {
      setTableInputValue(value);
    }
  }, [JSON.stringify(value)]);

  const isMobile = useCurrentScreenWidth() <= MAX_TABLET_WIDTH;

  const renderTableBody = () => {
    if (viewMode) {
      return (
        <TableInputViewMode
          value={tableInputValue}
          columns={columns}
          tableHeaderViewModeClassName={classNames?.tableHeaderViewMode}
          isExpanded={isFullScreen}
          isMobile={isMobile}
        />
      );
    }

    return (
      <TableInputEditMode
        {...props}
        value={tableInputValue}
        columns={columns}
        onRowInsert={handleInsertRowClick}
        onRowClear={handleClearRowClick}
        onRowDelete={handleDeleteRowClick}
        onValueUpdate={handleRowUpdate}
        onAddRowClick={handleAddRowClick}
        onRowDuplicate={handleDuplicateRowClick}
        isExpanded={isFullScreen}
        length={tableInputValue.length}
        rowsAmount={tableInputValue.length}
        validateOnRender={isViewModeChanged}
        latestAddedRowIndex={latestAddedRowIndex}
        onErrorMessageUpdate={onErrorMessageUpdate}
        tableIsMaxLength={tableInputValue.length >= MAX_TABLE_ROWS_AMOUNT}
        errors={errors}
        isMobile={isMobile}
      />
    );
  };

  return (
    <TableInputHeader
      label={label}
      onClick={toggleFullScreen}
      isExpanded={isFullScreen}
      viewMode={viewMode}
      errorMessage={errorMessage}
      classNames={classNames}
      required={required}
      titleHint={titleHint}
    >
      <div className={styles.tableWrapper}>
        {renderTableBody()}
      </div>
    </TableInputHeader>
  );
};

export default TableInput;
