import { FC } from 'react';
import Tag from 'components/digifi-wrappers/Tag';
import { IApplicationStatus } from 'api/digifi/ApplicationsApi';
import getApplicationStatusTagColor from 'utils/getApplicationStatusTagColor';
import formatMonetaryValue from 'product_modules/utils/formatMonetaryValue';
import maskApplicationStatusName from 'utils/maskApplicationStatusName';
import { useAppSelector } from 'hooks/reduxHooks';
import styles from './ApplicationDetailsHeader.module.scss';

interface IApplicationDetailsHeaderProps {
  applicationStatus: IApplicationStatus;
  productName: string;
  loanAmount: number;
}

const ApplicationDetailsHeader: FC<IApplicationDetailsHeaderProps> = ({
  applicationStatus,
  loanAmount,
  productName,
}) => {
  const { currency } = useAppSelector((state) => state.settings.variablesSettings);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.productName}>
          {productName}
          <Tag
            className={styles.status}
            color={getApplicationStatusTagColor(applicationStatus)}
          >
            {maskApplicationStatusName(applicationStatus)}
          </Tag>
        </div>
        <div className={styles.loanAmount}>
          {formatMonetaryValue(loanAmount, currency || '')}
        </div>
      </div>
    </div>
  );
};

export default ApplicationDetailsHeader;
