import { FocusEvent, useCallback, useEffect, useState } from 'react';
import {
  IBaseVariableConfiguration,
} from 'product_modules/api/LoanOriginationSystem/Base/LayoutConfigurationApi';
import { FormLayoutData, VariableValue } from 'product_modules/api/Types';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import Header from 'product_modules/components/Header';
import BorrowerProfileForm from 'components/ApplicationForm/forms/BorrowerProfileForm';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import LegalConsentsCheckbox from 'components/ApplicationForm/LegalConsentsCheckbox';
import WithFieldsValidationButton
  from 'components/digifi-wrappers/WithFieldsValidationButton/WithFieldsValidationButton';
import { ILegalDocument } from 'api/digifi/SettingsApi';
import { setSelectedDocument } from 'handlers/settingsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import useFakeLoader from 'hooks/useFakeLoader';
import styles from './CreateApplicationForm.module.scss';

interface ICreateApplicationFormProps<FieldType extends IBaseVariableConfiguration> {
  borrowerType: BorrowerType;
  borrowerProfileFormData: FormLayoutData;
  onBorrowerFieldChange: (field: FieldType, variable: Variable, value: VariableValue) => void;
  onBorrowerFieldBlur: (field: FieldType, variable: Variable, event?: FocusEvent<HTMLInputElement>) => void;
  onChangeCoBorrowerType: (coBorrowerType: BorrowerType, index: number) => void;
  coBorrowersFormData: FormLayoutData[];
  onCoBorrowerFieldChange: (index: number, variableConfiguration: IBaseVariableConfiguration, variable: Variable, value: VariableValue) => boolean;
  onCoBorrowerFieldBlur: (index: number, field: FieldType, variable: Variable, event?: FocusEvent<HTMLInputElement>) => void;
  coBorrowersTypes: BorrowerType[];
  availableBorrowerTypes: BorrowerType[];
  coborrowersCount: number;
  onSubmit: () => Promise<void>;
  onCreateApplicationFinish: () => void;
  isSubmitInProgress: boolean;
}

const MIN_LOADING_TIME = 5000;

const CreateApplicationForm = <FieldType extends IBaseVariableConfiguration>({
  borrowerType,
  borrowerProfileFormData,
  onBorrowerFieldChange,
  onBorrowerFieldBlur,
  coBorrowersFormData,
  onCoBorrowerFieldChange,
  onCoBorrowerFieldBlur,
  coBorrowersTypes,
  availableBorrowerTypes,
  onChangeCoBorrowerType,
  coborrowersCount,
  onSubmit,
  onCreateApplicationFinish,
  isSubmitInProgress,
}: ICreateApplicationFormProps<FieldType>) => {
  const dispatch = useAppDispatch();

  const [isLegalConsentChecked, setIsLegalConsentChecked] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<Record<string, boolean>>({});
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState<boolean>(false);

  const handleSubmitClick = useCallback(async () => {
    setIsSubmitButtonClicked(true);
    await onSubmit();
  }, [onSubmit]);

  const isShowingFakeLoader = useFakeLoader({
    shouldLoadingStart: isSubmitButtonClicked,
    minLoadingTime: MIN_LOADING_TIME,
  });

  const { legalDocuments } = useAppSelector(state => state.settings);
  const createApplicationLegalDocuments = legalDocuments.filter(document => document.showOnSubmitApplication);

  const handleLegalDocumentClick = (legalDocument: ILegalDocument) => {
    dispatch(setSelectedDocument(legalDocument));
  };

  const handleLegalConsentCheck = useCallback(() => {
    setIsLegalConsentChecked((prevState) => !prevState);
  }, []);

  const handleIsFormValidChange = useCallback((isValid: boolean, referenceKey: string) => {
    setIsFormValid((prevState) => ({ ...prevState, [referenceKey]: isValid }));
  }, []);

  useEffect(() => {
    if (isSubmitButtonClicked && !isSubmitInProgress && !isShowingFakeLoader) {
      onCreateApplicationFinish();
    }
  }, [isShowingFakeLoader, isSubmitButtonClicked, isSubmitInProgress, onCreateApplicationFinish]);

  const renderBorrowerForm = () => (
    <BorrowerProfileForm
      borrowerFormData={borrowerProfileFormData}
      coBorrowersFormData={coBorrowersFormData}
      onFieldChange={onBorrowerFieldChange}
      onFieldBlur={onBorrowerFieldBlur}
      availableBorrowerTypes={availableBorrowerTypes}
      selectedBorrowerType={borrowerType}
      formTitle={coborrowersCount ? 'Borrower Details' : undefined}
      onFormValidationChange={(isValid) => handleIsFormValidChange(isValid, 'borrower')}
      isBorrowerForm
    />
  );

  const renderCoBorrowerForm = (index: number) => {
    return (
      <BorrowerProfileForm
        key={index}
        borrowerFormData={coBorrowersFormData[index]}
        coBorrowersFormData={coBorrowersFormData}
        onFieldChange={(field, variable, value) => onCoBorrowerFieldChange(index, field, variable, value)}
        onFieldBlur={(field, variable, event) => onCoBorrowerFieldBlur(index, field as FieldType, variable, event)}
        selectedBorrowerType={coBorrowersTypes[index]}
        availableBorrowerTypes={availableBorrowerTypes}
        onChangeBorrowerType={(type) => onChangeCoBorrowerType(type, index)}
        formTitle={`Co-Borrower Details ${!index ? '' : index + 1}`}
        sectionTitleClassName={styles.sectionTitle}
        onFormValidationChange={(isValid) => handleIsFormValidChange(isValid, `coborrower_${index}`)}
      />
    );
  };

  const renderConsent = () => (
    <LegalConsentsCheckbox
      legalDocuments={createApplicationLegalDocuments}
      onLegalDocumentClick={handleLegalDocumentClick}
      onCheck={handleLegalConsentCheck}
      className={styles.checkboxWrapper}
      isChecked={isLegalConsentChecked}
    />
  );

  const renderActions = () => {
    return (
      <WithFieldsValidationButton
        size="form"
        kind="primary"
        disabled={!isLegalConsentChecked}
        onClick={handleSubmitClick}
        className={styles.buttonContainer}
        areFieldsInvalid={Object.values(isFormValid).some((isValid) => !isValid)}
        buttonClassName={styles.formButton}
        isLoading={isSubmitInProgress || isShowingFakeLoader}
      >
        Continue
      </WithFieldsValidationButton>
    );
  };


  const renderForm = () => {
    return (
      <>
        {renderBorrowerForm()}
        {Array.from({ length: coborrowersCount }).map((_, index) => renderCoBorrowerForm(index))}
        {renderConsent()}
        {renderActions()}
      </>
    );
  };

  return (
    <>
      <Header type='h1' className={styles.header}>Let’s Get Started</Header>
      <div className={styles.subHeader}>Please provide the information below to start your application.</div>
      <div className={styles.formWrapper}>
        {renderForm()}
      </div>
    </>
  );
};

export default CreateApplicationForm;
