import React, { FC, useCallback, useState } from 'react';
import { ScrollSync, AutoSizer } from 'react-virtualized';
import { TableValue, BasicVariableValue } from 'product_modules/api/Types';
import { TableColumn } from 'product_modules/api/Core/VariablesApi';
import { RowActionsContainer } from 'product_modules/components/RowActions';
import ButtonWithImage from 'product_modules/components/ButtonWithImage';
import WrapperWithTooltip from 'product_modules/components/Tooltip';
import TableHeaderGrid from 'product_modules/components/TableInput/TableHeaderGrid';
import TableInputGrid from './TableInputEditModeGrid';
import TableRowActionsGrid from './TableRowActionsGrid';
import styles from './TableInputEditMode.module.scss';
import ButtonWithImageWrapper from 'components/digifi-wrappers/ButtonWithImage/ButtonWithImage';

export const MAX_TABLE_ROWS_AMOUNT = 100;
const MIN_TABLE_CELL_WIDTH = 180;
const AMOUNT_OF_ROWS_VISIBLE = 10;
const EMPTY_TABLE_PLACEHOLDER = 'This table is empty. Start by adding a row.';

interface TableInputEditModeProps {
  value: TableValue;
  columns: TableColumn[];
  onRowInsert: (index: number) => void;
  onRowClear: (rowIndex: number, rowId: string) => void;
  onRowDelete: (rowIndex: number, rowId: string) => void;
  onValueUpdate: (id: string, updatedCellKey: string, updatedCellValue: BasicVariableValue) => void;
  onRowDuplicate: (rowIndex: number, rowId: string) => void;
  latestAddedRowIndex: number;
  onErrorMessageUpdate?: (errorInformation: Record<string, boolean>) => void;
  isExpanded?: boolean;
  rowActionsContainerClassName?: string;
  isFirstRow?: boolean;
  disabledValidation?: boolean;
  disableCapAttributesValidation?: boolean;
  disabled?: boolean;
  errors?: Record<string, boolean>;
  tableHasOneRow?: boolean;
  length: number;
  onAddRowClick?: (tableLength: number) => void;
  isStub?: boolean;
  rowsAmount: number;
  validateOnRender?: boolean;
  tableIsMaxLength?: boolean;
  isMobile?: boolean;
  dropdownClassName?: string;
}

const TableInputEditMode: FC<TableInputEditModeProps> = ({
  value,
  columns,
  isExpanded,
  length,
  disabled,
  disabledValidation,
  onAddRowClick,
  isStub,
  onRowClear,
  onRowInsert,
  onRowDuplicate,
  onRowDelete,
  tableIsMaxLength,
  tableHasOneRow,
  rowsAmount ,
  latestAddedRowIndex,
  isMobile,
  ...restProps
}) => {
  const [hoveredRowId, setHoveredRowId] = useState<string>('');

  const handleRowHover = useCallback((id: string) => {
    setHoveredRowId(id);
  }, []);

  const renderAddRowButton = (width: number) => {
    return (
      <div style={{ width: `${width}px`}}>
        <WrapperWithTooltip tooltip={!disabled && rowsAmount < MAX_TABLE_ROWS_AMOUNT ? 'Insert row' : ''}>
          <ButtonWithImageWrapper
            kind="add"
            title=""
            onClick={() => onAddRowClick?.(value.length)}
            className={styles.addRowButton}
            imageClassName={styles.addRowIcon}
            disabled={disabled || rowsAmount >= MAX_TABLE_ROWS_AMOUNT}
          />
        </WrapperWithTooltip>
      </div>
    );
  };

  return (
    <RowActionsContainer>
        <AutoSizer disableHeight>
          {({ width }) => (
            <>
              <ScrollSync>
                {({ onScroll, scrollLeft, scrollTop }) => (
                  <>
                    <TableRowActionsGrid
                      value={value}
                      onRowInsert={onRowInsert}
                      onRowDuplicate={onRowDuplicate}
                      onRowClear={onRowClear}
                      onRowDelete={onRowDelete}
                      onRowHover={handleRowHover}
                      scrollTop={scrollTop}
                      disabled={disabled}
                      tableIsMaxLength={tableIsMaxLength}
                      tableHasOneRow={tableHasOneRow}
                      hoveredRowId={hoveredRowId}
                      isExpanded={isExpanded}
                      amountOfRowsVisible={AMOUNT_OF_ROWS_VISIBLE}
                      isMobile={isMobile}
                    />
                    <div>
                      <TableHeaderGrid
                        width={width}
                        columns={columns}
                        disabled={disabled}
                        disabledValidation={disabledValidation}
                        scrollLeft={scrollLeft}
                        minCellWidth={MIN_TABLE_CELL_WIDTH}
                        isEmptyTableHeader={!value.length}
                        emptyTableHeaderClassName={styles.emptyTablePlaceholder}
                        emptyTablePlaceholder={EMPTY_TABLE_PLACEHOLDER}
                      />
                      <TableInputGrid
                        {...restProps}
                        columns={columns}
                        value={value}
                        width={width}
                        onScroll={onScroll}
                        onRowHover={handleRowHover}
                        disabledValidation={disabledValidation}
                        disabled={disabled}
                        isExpanded={isExpanded}
                        latestAddedRowIndex={latestAddedRowIndex}
                        minCellWidth={MIN_TABLE_CELL_WIDTH}
                        amountOfRowsVisible={AMOUNT_OF_ROWS_VISIBLE}
                        isMobile={isMobile}
                      />
                    </div>
                  </>
                )}
              </ScrollSync>
              {renderAddRowButton(width)}
            </>
          )}
        </AutoSizer>
    </RowActionsContainer>
  );
};

export default React.memo(TableInputEditMode);
