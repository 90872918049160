import React, { FC, useCallback, useState } from 'react';
import { TableColumn } from 'product_modules/api/Core/VariablesApi';
import { TableValue } from 'product_modules/api/Types';
import TableInputWrapped from 'product_modules/components/TableInput/TableInputWrapped';

interface TableErrorWrapperProps {
  isExpanded?: boolean;
  columns: TableColumn[];
  value: TableValue | null;
  label: string;
  viewMode?: boolean;
  onChange?: (value: TableValue | null) => void;
  hideTableContent?: boolean;
  disabledValidation?: boolean;
  disableCapAttributesValidation?: boolean;
  disabled?: boolean;
  classNames?: {
    tableHeaderViewMode?: string;
    headerContainerExpanded?: string;
    tableContainerExpanded?: string;
    labelExpanded?: string;
    collapseButton?: string;
    labelTooltip?: string;
  };
  required?: boolean;
  titleHint?: string;
}

const TABLE_ERROR_MESSAGE = 'All required columns must be filled in and the values entered must be valid.';

const TableErrorWrapper: FC<TableErrorWrapperProps> = ({ value, ...restProps }) => {
  const [isViewModeChanged, setIsViewModeChanged] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, boolean>>({});

  const handleErrorUpdate = useCallback((errorInformation: Record<string, boolean>) => {
    setErrors((previousState) => ({ ...previousState, ...errorInformation }));
  }, []);

  const handleViewModeChange = useCallback(() => {
    setIsViewModeChanged(true);
  }, []);

  return (
    <TableInputWrapped
      {...restProps}
      value={value}
      errorMessage={Object.values(errors).some((error) => error) ? TABLE_ERROR_MESSAGE : ''}
      onErrorMessageUpdate={handleErrorUpdate}
      isViewModeChanged={isViewModeChanged}
      onViewModeChange={handleViewModeChange}
      errors={errors}
    />
  );
};

export default TableErrorWrapper;
