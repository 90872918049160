import React, { FC, useCallback, useState } from 'react';
import { Grid, GridCellProps, OnScrollParams, SectionRenderedParams } from 'react-virtualized';
import { TableColumn } from 'product_modules/api/Core/VariablesApi';
import { TableValue, BasicVariableValue } from 'product_modules/api/Types';
import TableInputCell from './TableInputCell';
import getTableCellWidth from 'product_modules/components/TableInput/utils/getTableCellWidth';
import getTableHeight from 'product_modules/components/TableInput/utils/getTableHeight';
import styles from './TableInputEditMode.module.scss';
import ContainerShadows from 'product_modules/components/TableInput/ContainerShadows';

const DEFAULT_TABLE_ROW_HEIGHT = 53;

interface TableInputGridProps {
  width: number;
  columns: TableColumn[];
  value: TableValue;
  onScroll: (params: OnScrollParams) => void;
  onRowHover: (id: string) => void;
  onValueUpdate: (id: string, updatedCellKey: string, updatedCellValue: BasicVariableValue) => void;
  latestAddedRowIndex: number;
  minCellWidth: number;
  amountOfRowsVisible: number;
  onErrorMessageUpdate?: (errorInformation: Record<string, boolean>) => void;
  disabledValidation?: boolean;
  disableCapAttributesValidation?: boolean;
  disabled?: boolean;
  errors?: Record<string, boolean>;
  validateOnRender?: boolean;
  isExpanded?: boolean;
  isMobile?: boolean;
  dropdownClassName?: string;
}

const TableInputEditModeGrid: FC<TableInputGridProps> = ({
  width,
  columns,
  value,
  onScroll,
  onRowHover,
  isExpanded,
  errors,
  latestAddedRowIndex,
  minCellWidth,
  amountOfRowsVisible,
  isMobile,
  ...restProps
}) => {
  const [extremeRowsVisibility, setExtremeRowsVisibility] = useState({
    isFirstRowVisible: true,
    isLastRowVisible: true,
    isFirstColumnVisible: true,
    isLastColumnVisible: true,
  });

  const handleSectionRender = useCallback(({
    rowStartIndex,
    rowStopIndex,
    columnStartIndex,
    columnStopIndex,
    }: SectionRenderedParams) => {
    setExtremeRowsVisibility({
      isFirstRowVisible: !rowStartIndex,
      isLastRowVisible: rowStopIndex === value.length - 1,
      isFirstColumnVisible: !columnStartIndex,
      isLastColumnVisible: columnStopIndex === columns.length - 1,
    });
  }, [value.length, columns.length]);

  const renderTableInputBodyCell = (props: GridCellProps) => {
    return (
      <TableInputCell
        {...props}
        {...restProps}
        key={`${value[props.rowIndex]._id}-${columns[props.columnIndex].systemName}`}
        value={value[props.rowIndex][columns[props.columnIndex].systemName]}
        column={columns[props.columnIndex]}
        rowId={value[props.rowIndex]._id}
        style={props.style}
        onCellHover={onRowHover}
        hasError={errors?.[`${value[props.rowIndex]._id}-${columns[props.columnIndex].systemName}`]}
        columnIndex={props.columnIndex}
      />
    )
  };

  return (
    <>
      <Grid
        columnWidth={getTableCellWidth(width, columns.length, minCellWidth)}
        rowCount={value.length}
        height={getTableHeight(value.length, DEFAULT_TABLE_ROW_HEIGHT, amountOfRowsVisible, isExpanded, isMobile)}
        rowHeight={DEFAULT_TABLE_ROW_HEIGHT}
        cellRenderer={renderTableInputBodyCell}
        columnCount={columns.length}
        width={width}
        onScroll={onScroll}
        className={styles.tableInputGrid}
        scrollToRow={latestAddedRowIndex}
        overscanRowCount={0}
        onSectionRendered={handleSectionRender}
      />
      <ContainerShadows
        width={width}
        height={getTableHeight(value.length, DEFAULT_TABLE_ROW_HEIGHT, amountOfRowsVisible, isExpanded, isMobile)}
        extremeRowsVisibility={extremeRowsVisibility}
        defaultRowHeight={DEFAULT_TABLE_ROW_HEIGHT}
        bottomShadowClassName={styles.bottomShadow}
      />
    </>
  );
};

export default React.memo(TableInputEditModeGrid);
