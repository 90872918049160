import PopUp from 'components/digifi-wrappers/PopUp';
import { noop } from 'lodash';
import React, { FC, useState } from 'react';
import styles from './AdminPasswordRequiredPage.module.scss';
import PopUpContent from 'components/digifi-wrappers/PopUpContent';
import Button from 'components/digifi-wrappers/Button';
import PasswordInput from 'components/digifi-wrappers/PasswordInput';
import { HandleKeyDown } from 'product_modules/utils/TriggerEventOnEnterKeyDown';
import KeyEventEnum from 'product_modules/common/KeyEventEnum';
import { useAdminAuthApi } from 'providers/ApiServiceProvider';
import { useDispatch } from 'react-redux';
import { createNotification } from 'handlers/notificationsSlice';
import AppStorageKey from 'enums/AppStorageKey';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from 'enums/AppRoute';
import Notifications from 'components/Notifications/NotificationsLayout/Notifications';
import useSessionStorage from 'hooks/useSessionStorage';
import useBackUrlQueryParam from 'hooks/useBackUrlQueryParam';

const AdminPasswordRequiredPage: FC = () => {
  const adminAuthApi = useAdminAuthApi();
  const sessionStorage = useSessionStorage();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const backUrl = useBackUrlQueryParam();

  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isSubmitDisabled = !password;

  const handleSubmit = async () => {
    if (isSubmitDisabled || isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const token = await adminAuthApi.createToken(password);

      if (!token) {
        return createNotification({
          notification: 'Password is incorrect.',
          type: 'error',
          dispatch,
        });
      }

      sessionStorage.set(AppStorageKey.AdminAuthToken, token);

      navigate(backUrl || AppRoute.Base);
    } catch {
      createNotification({
        notification: 'Internal server error.',
        type: 'error',
        dispatch,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <PopUp
        title="Restricted Access"
        closePopUp={noop}
        classNames={{
          header: styles.popUpHeader,
          closeIcon: styles.popUpCloseIcon,
          sectionClassName: styles.popUpContainer,
        }}
        closable={false}
      >
        <PopUpContent hasTopMargin className={styles.popUpContent}>
          <div className={styles.description}>To proceed, please enter the password.</div>
          <PasswordInput
            labelTitle="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            tabIndex={1}
            autoFocus
            onKeyDown={HandleKeyDown(KeyEventEnum.Enter, handleSubmit)}
          />
          <div className={styles.buttonsContainer}>
            <Button
              kind="primary"
              size="form"
              disabled={isSubmitDisabled || isLoading}
              className={styles.submitButton}
              tabIndex={2}
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              Continue
            </Button>
          </div>
        </PopUpContent>
      </PopUp>
      <Notifications className={styles.notifications} />
    </div>
  );
};

export default AdminPasswordRequiredPage;
