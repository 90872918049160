const processHalf = (steps: number[], alwaysRender: number[], maxNumberOfStepsToHide: number) => {
  let currentNumberOfStepsToHide = maxNumberOfStepsToHide;

  return steps.reduce((accumulator, value) => {
    if (currentNumberOfStepsToHide && !alwaysRender.includes(value)) {
      currentNumberOfStepsToHide--;

      return accumulator;
    }

    accumulator.push(value);

    return accumulator;
  }, [] as number[]);
};

const getArrayOfStepsToRender = (totalNumberOfSteps: number, activeStep: number, maxNumberOfStepsToRender: number) => {
  const initialArrayOfElements = Array.from({ length: totalNumberOfSteps }, (_, i) => i + 1);
  const elementsBeforeActive = initialArrayOfElements.slice(0, activeStep - 1);
  const elementsAfterActive = initialArrayOfElements.slice(activeStep);

  const totalNumberOfStepsToHide = Math.max(totalNumberOfSteps - maxNumberOfStepsToRender, 0);

  const numberOfStepsToHideBeforeActive = totalNumberOfStepsToHide >= elementsBeforeActive.length
    ? (elementsBeforeActive.length - 1) // subtract 1 because first step is always visible
    : totalNumberOfStepsToHide;
  const numberOfStepsToHideAfterActive = totalNumberOfStepsToHide - numberOfStepsToHideBeforeActive;

  const processedFirstHalf = processHalf(
    elementsBeforeActive,
    [1],
    numberOfStepsToHideBeforeActive,
  );
  const processedSecondHalf = processHalf(
    elementsAfterActive.reverse(),
    [totalNumberOfSteps, totalNumberOfSteps - 1],
    numberOfStepsToHideAfterActive,
  );

  return [...processedFirstHalf, activeStep, ...processedSecondHalf.reverse()];
};

export default getArrayOfStepsToRender;
