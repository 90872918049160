import React, { useMemo } from 'react';
import clsx from 'clsx';
import { TableValue, VariableValue } from 'product_modules/api/Types';
import { IBaseVariableConfiguration } from 'product_modules/api/LoanOriginationSystem/Base/LayoutConfigurationApi';
import formatValueByVariable from 'product_modules/utils/formatValueByVariable';
import OverflowedText from 'product_modules/components/OverflowedText';
import { SkeletonTableView } from 'product_modules/components/Skeleton';
import { isEmptyVariableValue } from 'product_modules/utils/isEmptyVariableValue';
import useVariablesBySystemNames from 'product_modules/loaders/Variables/hooks/useVariablesBySystemNames';
import { BooleanValueDisplayFormat } from 'product_modules/utils/valueFormatters/formatBooleanValue';
import { TableVisualDataType } from 'product_modules/enums/VisualDataType';
import { TableVisualAttributes } from 'product_modules/api/Core/VariablesApi';
import TableInput from 'product_modules/components/TableInput';
import styles from './CardTableView.module.scss';

export interface CardTableViewProps<VariableConfigurationType extends IBaseVariableConfiguration> {
  className?: string;
  fieldClassName?: string;
  fieldTitleClassName?: string;
  fieldValueClassName?: string;
  data: Record<string, VariableValue>;
  fields: VariableConfigurationType[];
  formatVariableConfigurationDisplayTitle?: (title: string) => string;
  displayFieldsAttributes?: Record<string, boolean | undefined>;
  hiddenFieldClassName?: string;
  inputClassNames?: Partial<{
    address: Partial<{
      sectionClassName?: string;
      header?: string;
      popupContent?: string;
      closeIcon?: string;
      title?: string;
      saveButton?: string;
    }>;
    table: Partial<{
      headerContainer?: string;
      tableContainerExpanded?: string;
      labelExpanded?: string;
      collapseButton?: string;
    }>
  }>;
  inView?: boolean;
  renderFieldsOnlyInView?: boolean;
}

const DEFAULT_ITEM_HEIGHT = 45;

const CardTableView = <VariableConfigurationType extends IBaseVariableConfiguration>({
  data,
  formatVariableConfigurationDisplayTitle,
  fieldClassName,
  fieldTitleClassName,
  fieldValueClassName,
  className,
  displayFieldsAttributes,
  renderFieldsOnlyInView,
  fields,
  inputClassNames,
  hiddenFieldClassName,
  inView,
}: CardTableViewProps<VariableConfigurationType>) => {
  const usedVariablesSystemNames = useMemo(() => {
    return fields?.map((field) => field.variable);
  }, [fields]);

  const variables = useVariablesBySystemNames(usedVariablesSystemNames);

  if (!fields || !variables) {
    return (
      <SkeletonTableView fields={fields?.length} />
    );
  }

  const renderField = (field: VariableConfigurationType) => {
    const variable = variables[field.variable];

    if (!variable) {
      return null;
    }

    const visible = !displayFieldsAttributes
      || displayFieldsAttributes[field.id]
      || !isEmptyVariableValue(data[variable.systemName]);

    if (variable.visualDataType === TableVisualDataType.Table) {
      return visible && (
        <div key={field.id} className={styles.tableFieldContainer}>
          <div className={styles.tableField}>
            <TableInput
              label={formatVariableConfigurationDisplayTitle
                ? formatVariableConfigurationDisplayTitle(variable.name)
                : variable.name}
              columns={(variable.visualAttributes as TableVisualAttributes).columns}
              value={data[variable.systemName] as TableValue}
              viewMode
              classNames={{
                tableHeaderViewMode: fieldClassName ? styles.tableHeaderViewMode : '',
                ...inputClassNames?.table,
              }}
            />
          </div>
        </div>
      );
    }

    return (
      <div key={field.id} className={clsx(styles.field, !visible && styles.hiddenField, fieldClassName, !visible && hiddenFieldClassName)}>
        <div className={clsx(styles.fieldTitle, fieldTitleClassName)}>
          <OverflowedText>
            {formatVariableConfigurationDisplayTitle
              ? formatVariableConfigurationDisplayTitle(variable.name)
              : variable.name}
          </OverflowedText>
        </div>
        <div className={clsx(styles.fieldValue, fieldValueClassName)}>
          <OverflowedText>
            {formatValueByVariable(
              data[variable.systemName],
              variable,
              {
                booleanValueFormat: BooleanValueDisplayFormat.Capitalize,
              },
            )}
          </OverflowedText>
        </div>
      </div>
    );
  };

  const cardPreviewStyle = {
    gridTemplateColumns: `repeat(${fields.length}, minmax(0, 1fr))`,
    height: !inView ? `${fields.length * DEFAULT_ITEM_HEIGHT}px` : undefined,
  };

  return (
    <div style={cardPreviewStyle} className={clsx(styles.cardTableView, className)}>
      {(!renderFieldsOnlyInView || inView) && fields.map(renderField)}
    </div>
  );
};

export default CardTableView;
