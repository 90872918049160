import React, { FocusEvent } from 'react';
import CardComponent from 'product_modules/components/CardsForm/Card';
import { LoaderState } from 'product_modules/components/LoaderWithState';
import { BaseCalculation } from 'product_modules/api/LoanOriginationSystem/Base/CalculationsApi';
import {
  IBaseCard,
  IBaseVariableConfiguration,
} from 'product_modules/api/LoanOriginationSystem/Base/LayoutConfigurationApi';
import { FormLayoutData, VariableValue } from 'product_modules/api/Types';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import {
  useLayoutConfigurationEntities,
} from 'product_modules/loaders/LayoutConfiguration/hooks/useLayoutConfigurationEntities';
import styles from './Group.module.scss';

interface IGroupProps<
  FieldType,
  CalculationType
> {
  referenceKey: string;
  groupId: string;
  groupIndex: number;
  storageLayoutEntitiesPaths: {
    cards: string;
    variablesConfigurations: string;
  };
  data: FormLayoutData;
  onFieldChange: (field: FieldType, variable: Variable, value: VariableValue) => void;
  onFieldFocus?: (field: FieldType, variable: Variable, event: FocusEvent<HTMLInputElement>) => void;
  onFieldBlur?: (field: FieldType, variable: Variable, event?: FocusEvent<HTMLInputElement>) => void;
  onLoaderStateReset?: (variableConfiguration: FieldType) => void;
  formatVariableConfigurationDisplayTitle?: (title: string) => string;
  loaderStateById?: Record<string, LoaderState | null | undefined>;
  isEditMode?: boolean;
  displayFieldsAttributes?: Record<string, boolean | undefined>;
  calculations?: Map<string, CalculationType> | null;
  fieldClassName?: string;
  fieldTitleClassName?: string;
  fieldValueClassName?: string;
  hiddenFieldClassName?: string;
  country?: string;
  inputClassNames?: Partial<{
    address: Partial<{
      sectionClassName?: string;
      header?: string;
      popupContent?: string;
      closeIcon?: string;
      title?: string;
      saveButton?: string;
    }>;
    table: Partial<{
      headerContainerExpanded?: string;
      tableContainerExpanded?: string;
      labelExpanded?: string;
      collapseButton?: string;
    }>;
  }>;
  lastGroupClassName?: string;
  descriptionIconClassName?: string;
}

const Group = <
  CardType extends IBaseCard,
  FieldType extends IBaseVariableConfiguration,
  CalculationType extends BaseCalculation,
>({
  referenceKey,
  groupId,
  groupIndex,
  storageLayoutEntitiesPaths,
  data,
  onFieldChange,
  onFieldFocus,
  onFieldBlur,
  onLoaderStateReset,
  formatVariableConfigurationDisplayTitle,
  loaderStateById,
  isEditMode,
  displayFieldsAttributes,
  calculations,
  fieldClassName,
  fieldTitleClassName,
  fieldValueClassName,
  hiddenFieldClassName,
  country,
  inputClassNames,
  lastGroupClassName,
  descriptionIconClassName,
}: IGroupProps<FieldType, CalculationType>) => {
  const cards = useLayoutConfigurationEntities<CardType>(
    referenceKey,
    storageLayoutEntitiesPaths.cards,
    groupId,
    'sortedCardsByGroup',
  );

  const hideCardHeader = cards.every((card) => !card.name && !card.description);

  return (
    <div className={styles.groupContainer}>
      {cards.map((card, cardIndex) => (
        <CardComponent
          referenceKey={referenceKey}
          storageLayoutEntitiesPaths={storageLayoutEntitiesPaths}
          card={card as IBaseCard}
          cardIndex={cardIndex}
          key={card.id}
          data={data}
          onFieldChange={onFieldChange}
          onFieldBlur={onFieldBlur}
          onFieldFocus={onFieldFocus}
          loaderStateById={loaderStateById}
          onLoaderStateReset={onLoaderStateReset}
          isEditMode={isEditMode}
          formatVariableConfigurationDisplayTitle={formatVariableConfigurationDisplayTitle}
          displayFieldsAttributes={displayFieldsAttributes}
          rowIndex={groupIndex}
          calculations={calculations}
          fieldClassName={fieldClassName}
          fieldTitleClassName={fieldTitleClassName}
          fieldValueClassName={fieldValueClassName}
          hiddenFieldClassName={hiddenFieldClassName}
          country={country}
          inputClassNames={inputClassNames}
          lastRowCardClassName={lastGroupClassName}
          descriptionIconClassName={descriptionIconClassName}
          hideCardHeader={hideCardHeader}
        />
      ))}
    </div>
  );
};

export default Group;
